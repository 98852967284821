// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cv-js": () => import("./../../../src/pages/cv.js" /* webpackChunkName: "component---src-pages-cv-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jasm-js": () => import("./../../../src/pages/jasm.js" /* webpackChunkName: "component---src-pages-jasm-js" */),
  "component---src-pages-kyoto-2019-js": () => import("./../../../src/pages/kyoto2019.js" /* webpackChunkName: "component---src-pages-kyoto-2019-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-travels-js": () => import("./../../../src/pages/travels.js" /* webpackChunkName: "component---src-pages-travels-js" */)
}

